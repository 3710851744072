export default {
  audit: {
    alocationSituation: 'Situação Alocação',
    sendSituation: 'Situação Envio',
    alocation: 'Alocação',
    stores: 'Lojas',
    auditHistory: 'Histórico de Alocação',
    productCode: 'Código do Produto',
    skuCode: 'Código SKU',
    sizeCode: 'Código Tamanhho',
    storeOrders: 'Pedidos Loja',
    quantityAlocated: 'Quantidade alocada',
    quantityMissing: 'Quantidade faltante',
    quantityDemanded: 'Quantidade pedida',
    color: 'Sortimento',
    productName: 'Nome do Produto',
    storeCode: 'Código Loja',
    totalPieces: 'Total de Peças',
    message: 'Mensagem',
    information: 'Informação',
    emissionDate: 'Data de Emissão',
    integrationDate: 'Data da Integração',
    store: 'Loja',
    order: 'Pedido',
    notIntegrated: 'Pedido não integrado ao ERP',
  },
};
